*,
*::after,
*::before {
	box-sizing: border-box;
}

:root {
	font-size: 18px;
	--color-text: #fff;
	--color-bg: #000;
	--color-link: #ea00d9;
	--color-link-hover: #3eaaf1;
}

body {
	margin: 0;
	color: var(--color-text);
	background-color: var(--color-bg);
	font-family: ballinger-mono, BlinkMacSystemFont, Segoe UI, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

/* Page Loader */
.js .loading::before,
.js .loading::after {
	content: '';
	position: fixed;
	z-index: 1000;
}

.js .loading::before {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--color-bg);
}

.js .loading::after {
	top: 50%;
	left: 50%;
	width: 60px;
	height: 60px;
	margin: -30px 0 0 -30px;
	border-radius: 50%;
	opacity: 0.4;
	background: var(--color-link);
	animation: loaderAnim 0.7s linear infinite alternate forwards;

}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.5, 0.5, 1);
	}
}

a {
	text-decoration: none;
	color: var(--color-link);
	outline: none;
	cursor: pointer;
	white-space: nowrap;
	position: relative;
}


a:hover {
	color: var(--color-link-hover);
	outline: none;
}

.content8 a::before {
	content: '';
	height: 1px;
	width: 100%;
	background: currentColor;
	position: absolute;
	top: 90%;
	transition: transform 0.3s;
	transform-origin: 0% 50%;
}

.content8 a:hover::before {
	transform: scaleX(0);
	transform-origin: 100% 50%;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
	/* Provide a fallback style for browsers
	 that don't support :focus-visible */
	outline: none;
	background: lightgrey;
}

a:focus:not(:focus-visible) {
	/* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
	background: transparent;
}

a:focus-visible {
	/* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
	outline: 2px solid red;
	background: transparent;
}

.unbutton {
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	font: inherit;
	cursor: pointer;
}

.unbutton:focus {
	outline: none;
}

main {
	height: 100vh;
	display: flex;
	flex-direction: column;
}

.frame {
	padding: 5vh 2rem 0;
	/* padding: 3rem 5vw; */
	text-align: center;
	position: relative;
	z-index: 1000;
}

.frame__title-wrap {
	text-align: center;
}

.frame__links {
	display: inline;
	text-align: center;
	margin-top: 2%;

}

.frame__demos {
	display: inline;
	text-align: center;
	margin-top: 2%;
}

.frame__links a {
	border: 0;
	color: inherit;
	white-space: nowrap;
	background: none;
	text-transform: inherit;
	font: inherit;
	cursor: pointer;
	color: var(--color-link);
	font-size: 12px;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
}

.frame__links a::before {
	content: "[";
	margin-right: 0.5rem;
	display: inline-block;
}

.frame__links a::after {
	content: "]";
	margin-left: 0.5rem;
	display: inline-block;
}

.frame__links a:hover {
	color: var(--color-link-hover);
}


dl {
	margin-bottom: 0;

}

.copyright {
	font-size: 10px;
}

.ascii {
	color: var(--color-link);
	opacity: 0.5;
	position: fixed;
	right: 0;
	top: -1rem;
	pointer-events: none;
}

.content,
.content2,
.content3,
.content4,
.content5,
.content6,
.content7,
.content8 {
	display: grid;
	gap: 1rem;
	max-width: 830px;
	padding: 0 3rem 0;
}

.content a {
	display: grid;
	max-width: 830px;

	color: white;
}

/* .content a:hover {
	color: var(--color-link-hover);
} */





dt {
	font-weight: bold;
	text-transform: uppercase;
}

dd {
	margin: 0;
}

.faq-title {
	font-weight: bold;
}

.footer {
	margin-top: auto;
	padding: 2rem;
	display: flex;
	flex-wrap: wrap;
	gap: 1rem;
	text-align: right;
}


.splitting .words .word {
	white-space: nowrap;
}

@media screen and (min-width: 53em) {
	.frame {
		display: grid;
		grid-template-columns: 25% 50% 25%;
		grid-template-rows: auto;
		grid-template-areas: 'title prev sponsor';
		justify-content: start;
	}


	.frame__title-wrap {
		text-align: left;
	}


	/* .frame {
		position: fixed;
		text-align: center;
		z-index: 100;
		top: 0;
		left: 0;
		display: grid;
		align-content: space-between;
		width: 100%;
		max-width: none;
		height: 100vh;
		padding: 3rem 5vw;
		pointer-events: none;
		grid-template-columns: 25% 50% 25%;
		grid-template-rows: auto auto auto;
		grid-template-areas: 'title links author' '... ... ...' '... demos ...';
	} */

	.content,
	.content2,
	.content3,
	.content4,
	.content5,
	.content6,
	.content7,
	.content8 {
		grid-template-columns: 15ch 1fr;
	}

	.content a {
		grid-template-columns: 15ch 1fr;
	}



}